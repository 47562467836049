<template>
  <el-card class="box" style="margin-top: 10px;">
    <div>
      <div class="herder_top_main">
        <div class="top_main_hene"></div>
        <div class="heneone">用户详情</div>
      </div>
      <div class="table_box">
        <div class="esal_tal">
          <div class="dids"></div>
          <div class="didses">基本信息</div>
        </div>
        <div class="dis_top">
          <div class="top_left">
            <div class="top_top">
              <div class="left_img">
                <img v-if="data.avatar" :src="data.avatar" alt="" />
                <img v-else src="../../assets/images/profile.png" alt="" />
              </div>
              <div class="left_shanghu" v-if="data.hasStore">商户</div>
            </div>
            <div class="left_center">{{ data.nickName }}</div>
            <div class="left_bottom" v-show="data.level == null ? false : true">
              <div v-for="item in level" :key="item.id">
                {{ item.id == data.level ? item.name : "" }}
              </div>
            </div>
          </div>
          <div class="top_center">
            <div class="center_font">ID: {{ data.id }}</div>
            <div class="center_font">手机号: {{ data.mobile }}</div>
            <div class="center_font">注册时间：{{ data.createTime }}</div>
            <div class="center_font" v-if="data.channel == 0">
              用户来源：{{ "pc" }}
            </div>
            <div class="center_font" v-if="data.channel == 1">
              用户来源：{{ "APP" }}
            </div>
            <div class="center_font" v-if="data.channel == 2">
              用户来源：{{ "小程序" }}
            </div>
          </div>
          <div class="top_right">
            <div class="center_font">消费金额：¥{{ data.balance }}</div>
            <div class="center_font">订单数量：{{ data.score }}</div>
            <div class="center_font">积分：{{ data.score }}</div>
            <div
              class="center_font"
              v-if="data.storeName"
              @click="Jump(data.storeId)"
            >
              店铺名称：<font color="#0066ff">{{ data.storeName }}</font>
            </div>
          </div>
        </div>
        <div class="table_box" style="margin-top: 100px">
          <div class="esal_tal">
            <div class="dids"></div>
            <div class="didses">收货人信息</div>
          </div>
          <div>
            <el-table
              :data="data.addressList"
              border
              style="width: 1011px; margin-left: 40px; margin-top: 10px"
              :header-cell-style="tableHeaderColor"
              size="medium"
            >
              <el-table-column prop="realname" label="姓名" width="120">
              </el-table-column>
              <el-table-column prop="mobile" label="手机号" width="135">
              </el-table-column>
              <el-table-column label="详情地址" width="655">
                <template v-slot:default="scope">
                  {{
                    scope.row.province +
                    scope.row.city +
                    scope.row.district +
                    scope.row.specific
                  }}
                </template>
              </el-table-column>
              <el-table-column prop="tag" label="是否默认" width="100">
                <template v-slot:default="scope">
                  {{ scope.row.perset == true ? "是" : "否" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>
import { memberDetail, memberSite, memberLevel } from "@/api/member";
export default {
  data() {
    return {
      data: [],
      id: "",
      level: [],
    };
  },
  created() {
    this.memList();

  },
  methods: {
    // table
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #666666;font-weight: 700;";
      }
    },
    //获取会员详情
    memList() {
      let id = this.$route.query;
      memberDetail({ id: id.id }).then((res) => {
        this.data = res.data;
      });
      //获取会员收货人地址
      memberSite({ memberId: id.id }).then((res) => {
        this.tableData = res.data;
      });
      //会员等级
      memberLevel().then((res) => {
        this.level = res.data.list;
      });
    },
    // 店铺详情
    Jump(id) {
      this.$router.push({
        path: "/store/detali",
        query: { id: id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  background: #ffffff;
  border-radius: 10px;
}
.herder_top_main {
  display: flex;
  width: 100%;
  height: 50px;
  .top_main_hene {
    margin-top: 8px;
    width: 5px;
    height: 20px;
    background-color: #0066ff;
    margin-top: 13px;
  }
  .heneone {
    font-size: 16px;
    line-height: 50px;
    margin-left: 10px;
    font-weight: bold;
  }
}
.table_box {
  margin-top: 50px;
  .esal_tal {
    display: flex;
    .dids {
      margin-left: 20px;
      width: 15px;
      height: 15px;
      background-color: rgba(194, 128, 255, 1);
    }
    .didses {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .dis_top {
    display: flex;
    .top_left {
      .top_top {
        display: flex;
        justify-content: center;
        .left_shanghu {
          font-size: 12px;
          color: #ffffff;
          line-height: 20px;
          text-align: center;
          width: 28px;
          height: 20px;
          margin-left: -40px;
          margin-top: 10px;
          background-color: red;
        }
        .left_img {
          width: 114px;
          height: 114px;
          border-radius: 50%;
          margin-bottom: 10px;
          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }
        }
      }
      margin-left: 50px;
      margin-top: 20px;
      .left_center {
        width: 200px;
        font-size: 15px;
        text-align: center;
        word-wrap: break-word;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /*控制可显示几行文本*/
      }
      .left_bottom {
        width: 67px;
        height: 20px;
        margin-top: 8px;
        margin-left: 65px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        background-color: #ffa94c;
        color: #ffffff;
        border-radius: 50px;
      }
    }
    .top_center {
      margin-top: 20px;
      margin-left: 50px;
      font-size: 14px;
      .center_font {
        margin-top: 20px;
      }
    }
    .top_right {
      margin-top: 40px;
      margin-left: 100px;
      font-size: 14px;
      .center_font {
        margin-top: 15px;
      }
    }
  }
}
</style>
<style lang="less">
.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  text-align: center;
}
</style>
